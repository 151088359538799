<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-search
        v-model="text"
        readonly="false"
        @focus="search"
        placeholder="请输入搜索关键词"
      ></van-search>
    </div>
    <div class="classify">
      <van-grid>
        <van-grid-item
          :text="classify.name"
          v-for="classify in classifys"
          :key="classify.code"
          @click="classifySearch(classify.code)"
        >
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="classify.icon"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="更多" @click="more">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-more"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="advice">
      <van-image
        class="img"
        :src="require('../../../../assets/images/train/adv-01.jpg')"
      ></van-image>
    </div>
    <van-tabs v-model="activeTab" :color="COLOR_M" border="true">
      <van-tab>
        <template #title> <van-icon name="fire-o" /> 热门推荐 </template>
        <van-list
          :finished="finished"
          finished-text="没有更多了"
          offset="0"
          class="task-list"
          :immediate-check="false"
          @load="retrieveTaskHotList"
        >
          <template #default>
            <van-row>
              <van-col
                @click="detail(task.code)"
                v-for="task in taskList"
                :key="task.code"
                span="12"
              >
                <div class="task">
                  <div class="lable">
                    <van-tag :color="COLOR_S1">热门</van-tag>
                  </div>
                  <div class="image">
                    <van-image :src="task.imagePath"></van-image>
                  </div>
                  <div class="name text-short">{{ task.name }}</div>
                  <van-row>
                    <van-col span="6" class="type"
                      ><van-tag :color="COLOR_M" v-if="task.type === 'ONE'"
                        >线上</van-tag
                      ></van-col
                    >
                    <van-col span="18" class="price color-s2"
                      ><van-icon name="cash-back-record" />
                      <span v-if="task.priceMin != 0">{{ task.priceMin }}</span
                      ><span v-if="task.priceMin != 0 && task.priceMax != 0"
                        >~</span
                      >
                      <span v-if="task.priceMax != 0">{{ task.priceMax }}</span
                      >元</van-col
                    >
                  </van-row>
                  <!-- <div class="cert">
                <van-tag plain :color="COLOR_S1">{{ task.cert }}</van-tag>
              </div> -->
                </div>
              </van-col>
            </van-row>
            <van-row v-if="taskList.length <= 0">
              <van-col span="24" class="no-record">
                <van-image
                  width="103"
                  height="103"
                  :src="require('../../../../assets/images/home/no-record.png')"
                ></van-image>
              </van-col>
            </van-row>
          </template> </van-list
      ></van-tab>
      <van-tab>
        <template #title><van-icon name="cluster-o" /> 发证机构 </template>
        <div class="issuer">
          <van-row
            class="item"
            v-for="issuer in issuerList"
            :key="issuer.code"
            @click="issuerCert(issuer.code)"
          >
            <van-col span="3" offset="1"
              ><div class="icon">
                {{ issuer.name.substring(0, 1) }}
              </div></van-col
            >
            <van-col span="14" class="name text-short">{{
              issuer.name
            }}</van-col>
            <van-col span="5" class="count">{{ issuer.count }}个证书</van-col>
          </van-row>
        </div>
      </van-tab>
    </van-tabs>

    <van-overlay :show="guideShow">
      <div class="wrapper" @click="guideShow = false">
        <div class="guide">
          <van-image
            class="img"
            :src="require('../../../../assets/images/guide.jpg')"
          ></van-image>
          <van-button
            plain
            :color="COLOR_M"
            size="mini"
            class="btn"
            icon="info-o"
            @click="guideShow = false"
            >我了解了</van-button
          >
        </div>
      </div>
    </van-overlay>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="TRA" module-label="TRA_HOME_INDEX" />
    <Tabbar active="home" v-if="platform === 'WPP'" />
  </div>
</template>
<script>
import { Search, Tabs, Tab, Tag, Overlay, NoticeBar, Image, Grid, GridItem, Sticky, Icon, List } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import '../../../../assets/icon/font-icon.js'
export default {
  components: {
    Tabbar: Tabbar,
    Login: Login,
    Share: Share,
    Loading: Loading,
    LogVisit: LogVisit,
    [Search.name]: Search,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Tag.name]: Tag,
    [Overlay.name]: Overlay,
    [NoticeBar.name]: NoticeBar,
    [Image.name]: Image,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Sticky.name]: Sticky,
    [Icon.name]: Icon,
    [List.name]: List
  },
  data () {
    return {
      platform: '',
      loadingShow: false,
      finished: false,
      guideShow: false,
      activeTab: 'HOT',
      sharer: '',
      page: { current: 0, size: 10, last: 0 },
      classifys: [],
      taskList: [],
      issuerList: [],
      loginState: 'N'
    }
  },
  mounted () {
    document.title = '证书培训'
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
      var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
      if (token !== undefined && token !== '' && token !== null) {
        var guide = window.sessionStorage.getItem('guide_show')
        if (guide !== 'N') {
          // this.guideShow = true
          // window.sessionStorage.setItem('guide_show', 'N')
        }
        this.retrieveClassifys()
        this.retrieveTaskHotList()
        this.retrieveIssuerList()
        this.initShare()
        this.$refs.logVisit.createLog()
      }
    },
    initShare () {
      var title = '获取技能证书，就上微职客'
      var desc = '涵盖所有技能证书，通过率100%,快速下证，助您升职加薪!'
      var link = 'https://moc.utopanet.com/train/home?f=1'
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.initParam(title, desc, link, logo)
    },
    async retrieveClassifys () {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var pd = { operatorCode: operator }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/classify/retrieveClassifyRMDList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.classifys = res.data
      }
    },
    async retrieveTaskHotList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
        var pd = { operatorCode: operator, current: this.page.current, size: this.page.size }
        const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveTaskHotList', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.taskList = this.taskList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
        this.loadingShow = false
      }
    },
    async retrieveIssuerList () {
      var pd = { current: 1, size: 100 }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/issuer/retrieveIssuerList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.issuerList = res.data
      }
    },
    detail (code) {
      this.$router.push({ path: '/mde/train/detail', query: { taskCode: code } })
    },
    classifySearch (code) {
      this.$router.push({ path: '/mde/train/search', query: { classifyCode: code } })
    },
    issuerCert (code) {
      this.$router.push({ path: '/mde/train/cert', query: { issuerCode: code } })
    },
    more () {
      this.$router.push({ path: '/mde/train/classify' })
    },
    search () {
      this.$router.push({ path: '/mde/search/home', query: { type: 'TRA' } })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  background: #fff;
  ::v-deep .search {
    .van-search {
      background-color: transparent;
    }
  }
}
.title {
  height: 25px;
  line-height: 25px;
  border-bottom: 1px solid #eee;
  padding: 5px 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 800;
}
.text {
  color: #fff;
}
::v-deep .notice {
  .van-notice-bar {
    position: relative;
    display: flex;
    align-items: center;
    height: 26px;
    margin: 1px 0px;
    padding: 0 5px;
    color: #ed6a0c;
    font-size: 13px;
    line-height: 24px;
    background-color: #fffbe8;
  }
}
.classify {
  margin-top: 1px;
  .icon {
    width: 30px;
    height: 30px;
  }
}
.task-list {
  padding-bottom: 60px;
  width: 100%;
  position: absolute;
  .task {
    text-align: left;
    margin: 5px;
    padding-bottom: 5px;
    border: 1px solid #eee;
    position: relative;
    .image {
    }
    .lable {
      float: left;
      z-index: 1;
      right: 0;
      position: absolute;
    }
    .name {
      padding-left: 5px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
      text-align: left;
      font-size: 14px;
    }
    .type {
      padding-left: 5px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #bbb;
    }
    .price {
      font-size: 12px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
      text-align: right;
      padding-right: 10px;
    }
    .cert {
      padding-left: 5px;
      height: 20px;
      line-height: 20px;
      font-size: 10px;
      color: #bbb;
    }
    ::v-deep .van-tag {
      font-size: 10px;
    }
  }
}
.issuer {
  margin-bottom: 60px;
  .item {
    border-bottom: 1px solid #eee;
    .icon {
      padding-top: 2px;
      background-color: #00cc99;
      color: #fff;
      height: 30px;
      width: 30px;
      font-size: 18px;
      font-weight: 600;
      border-radius: 20px;
      margin-left: 5px;
      margin-top: 5px;
    }
    .name {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      line-height: 40px;
    }
    .count {
      line-height: 40px;
      text-align: left;
    }
  }
}

.advice {
  border-top: 1px solid #416443;
  border-bottom: 1px solid #416443;
  .img {
    width: 100%;
  }
}
.wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .guide {
    background-color: #fff;
    width: 80%;
    padding-bottom: 30px;
    .img {
      width: 100%;
    }
    .btn {
      width: 60%;
    }
  }
}
.no-record {
  margin-top: 80px;
}
</style>
